// axios.js
import axios from 'axios';

const instance = axios.create({
 // baseURL: 'http://localhost:8000', // replace with your backend URL
 //   baseURL: 'http://194.195.115.192:8000', // replace with your backend URL
 // baseURL: 'http://innohubrc.com', // replace with your backend URL
   // baseURL: 'https://innohubrc.com/api
   baseURL: 'https://backend.innohubrc.com'
});

export default instance;
