import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";


const AgrProject5 = () => {
  const navigate = useNavigate();

  const onGroupClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onResearchAreaTextClick = useCallback(() => {
    navigate("/researcharea");
  }, [navigate]);

  const onProjectsTextClick = useCallback(() => {
    navigate("/desktop-3");
  }, [navigate]);

  const onAboutUsTextClick = useCallback(() => {
    navigate("/about-us");
  }, [navigate]);

  const onAboutUsText2Click = useCallback(() => {
    navigate("/researcharea");
  }, [navigate]);

  const onCareersTextClick = useCallback(() => {
    navigate("/desktop-3");
  }, [navigate]);

  return (
    <div className="relative bg-gray md:w-full w-[739px] p-0 mx-full">
      <Header />
      <div className="bg-gray py-16 mx-10 font-poppins">
        <div className="container mx-auto">
          <div className="px-4 md:px-0">
            <div className="absolute w-full top-[95px] right-[0px] left-[0px] bg-gradient-to-b from-black [background:linear-gradient(180deg,_#09011a,_rgba(105,_63,_196,_0.57)_43%,_rgba(163,_134,_223,_0)_91%)]  h-[466px] overflow-hidden text-4xl md:text-5xl lg:text-6xl">
              <div className="absolute top-[111px] left-[910px] rounded-381xl [background:radial-gradient(50%50%_at_50%_50%,_rgba(120,_100,_161,_0.53)_26.5%,#1c054e_39.88%,_rgba(223,_208,_255,_0))] w-[827px] h-[826px] overflow-hidden hidden" />
              <div className="absolute top-[181px] left-[50%] transform -translate-x-1/2 rounded-full bg-gray-400 w-[686px] h-[686px] overflow-hidden" />
              <img
                className="absolute top-[0px] left-[50%] transform -translate-x-1/2 w-full md:max-w-[1160px] md:h-[375.6px] md:w-auto h-auto"
                alt=""
                src="/artboard-44x-1@2x.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto pt-[300px] font-poppins ">
        <b className="text-29xl md:text-29xl m-5 font-bold text-blue flex content-center items-center">AI-Based Market Forecasting for Agribusiness:
          Enhancing Decision Support Systems

        </b>
        <h3 className="text-13xl text-blue m-5" >Synopsis:</h3>
        <p className="mb-10 m-5 leading-relaxed text-cold-grey-white text-justify font-poppins text-13xl md:text-xl md:mb-5 lg:mb-5 xl:mb-5 lg:text-base transform scale-120">
          The research project, "AI-Based Market Forecasting for Agribusiness: Enhancing Decision Support Systems," aims to leverage artificial intelligence (AI) to revolutionize market predictions in the agribusiness sector. The comprehensive approach involves a literature review, identification of market forecasting challenges, exploration of AI solutions, development of AI models, and integration into decision support systems. The project emphasizes customization, evaluation of external factors, and addresses data security and privacy. It assesses economic and environmental impacts, identifies adoption challenges, and explores future trends.
        </p>



        <h2 className="text-29xl lg:text-17xl text-blue m-5" >AI Tool Name: AgriForecast Pro
        </h2>
        <h3 className="text-17xl text-blue m-5" >Objective:</h3>

        <p className="mb-5 m-5 leading-relaxed text-cold-grey-white text-justify font-poppins text-13xl md:text-xl  lg:text-base transform scale-120">  AgriForecast Pro covers diverse features for accurate market predictions and decision support tailored for the agribusiness sector.



        </p>

        <h3 className="text-29xl lg:text-17xl text-blue m-5" >Key Features:</h3>
        <ul className="mb-5 m-5 leading-relaxed text-cold-grey-white text-justify font-poppins text-13xl md:text-xl  lg:text-base transform scale-120">
          <li className="p-1 "> AI-Driven Market Prediction: Utilizes advanced AI models for accurate and timely market forecasts.
          </li>
          <li className="p-1">
            Decision Support Integration: Seamlessly integrates AI predictions into decision support systems for actionable insights.
          </li>
          <li className="p-1">
            Customization Module: Allows customization of AI models for specific agribusiness sectors, crops, and geographic regions.
          </li>
          <li className="p-1">  Risk Assessment: Evaluates external factors and risks, including weather events, regulatory changes, and global market trends.
          </li>
          <li className="p-1">  Data Security Assurance: Ensures robust data security and privacy measures for sensitive market data.

          </li>
        </ul>

        <h3 className="text-29xl lg:text-17xl  text-blue m-5" >Target Audience:</h3>
        <ul className="mb-5 m-5 leading-relaxed text-cold-grey-white text-justify font-poppins text-13xl md:text-xl  lg:text-base transform scale-120">
          <li className="p-1">Agribusiness Executives
          </li>
          <li className="p-1">Market Analysts</li>
          <li className="p-1">Agricultural Economists</li>

          <li className="p-1">Decision Support System Developers</li>
          <li className="p-1">Agricultural Technology Providers</li>
        </ul>

        <h3 className="text-29xl lg:text-17xl  text-blue m-5" >Project Impact:</h3>
        <ul className="mb-5 m-5 leading-relaxed text-cold-grey-white text-justify font-poppins text-13xl md:text-xl  lg:text-base transform scale-120 ">
          <li className="p-1">Strategic Decision Improvement: Enhances decision-making for agribusinesses through accurate and timely market predictions.

          </li>
          <li className="p-1">Customized Insights: Provides tailored insights for different agribusiness sectors, promoting adaptability.</li>
          <li className="p-1">Risk Mitigation: Addresses external risks, contributing to effective risk management in market forecasting.</li>
          <li className="p-1">Economic Efficiency: Evaluates economic implications, fostering cost savings and overall profitability.</li>
          <li className="p-1">Barriers Mitigation: Identifies and addresses challenges, providing adoption strategies for seamless integration.</li>


        </ul>
        <h3 className="text-29xl lg:text-17xl  text-blue m-5" >Conclusion:</h3>
        <p className="mb-[200px] m-5 leading-relaxed text-cold-grey-white text-justify font-poppins text-13xl md:text-xl  lg:text-base transform scale-120">
          AgriForecast Pro stands as a cutting-edge AI tool, aligning with the project's goals by offering tailored market forecasting solutions for agribusinesses. Its features empower decision-makers, fostering economic efficiency, risk mitigation, and future-ready market forecasting capabilities. AgriForecast Pro serves as a catalyst for informed and strategic decision-making in the dynamic landscape of agribusiness.

        </p>
      </div>
      <Footer />
    </div>
  );
};

export default AgrProject5;
