import React, { useState } from "react";
import { IoMdLock } from "react-icons/io";
import TextField from "../../../components/formcomponents/TextField";
import { updatePassword } from "../../../redux/actions/action";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { toast } from "react-toastify";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa6";
import { RiLockPasswordLine } from "react-icons/ri";

const ChangePassword = ({ onClose }) => {
  const dispatch = useDispatch();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false); // New state for New Password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // New state for Confirm Password visibility
  const [showStrengthHint, setShowStrengthHint] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  const toggleNewPasswordVisibility = () => {
    setShowNewPassword((prevShowNewPassword) => !prevShowNewPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(
      (prevShowConfirmPassword) => !prevShowConfirmPassword
    );
  };
  const validatePassword = (password) => {
    const lengthCriteria = /.{8,}/;
    const numberCriteria = /[0-9]/;
    const uppercaseCriteria = /[A-Z]/;
    const lowercaseCriteria = /[a-z]/;
    const specialCharCriteria = /[!@#$%^&*(),.?":{}|<>]/;
    if (
      lengthCriteria.test(password) &&
      numberCriteria.test(password) &&
      uppercaseCriteria.test(password) &&
      specialCharCriteria.test(password) &&
      lowercaseCriteria.test(password)
    ) {
      return true;
    }
    return false;
  };
  const newPswOnchange = (e) => {
    setNewPassword(e.target.value);
    if (validatePassword(newPassword)) {
      setShowStrengthHint(false);
      setIsButtonEnabled(true);
    } else {
      setShowStrengthHint(true);
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newPassword === confirmPassword) {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("No token found");
      } else {
        const decodedToken = jwtDecode(token);
        dispatch(
          updatePassword(decodedToken.id, newPassword, confirmPassword)
        ).then(onClose);
      }
    } else {
      window.alert("New Password and Confirm Password Must be same!");
    }
  };

  return (
    <div className="absolute w-full ml-60 top-0 flex items-center justify-center bg-color rounded-md border-solid border-white border-2">
      <form className="bg-color p-9 rounded shadow-sm max-w-sm">
        <h2 className="text-2xl font-semibold text-white text-center mb-6 ml-6">
          Change Password
        </h2>
        <div className="relative mb-6">
          <TextField
            label="New Password:"
            name="new-password"
            type={showNewPassword ? "text" : "password"} // Toggle input type for New Password
            placeholder="Enter new password"
            value={newPassword}
            onChange={(e) => newPswOnchange(e)}
            required={true}
            disabled={false}
            icon={IoMdLock}
            className=""
          />
          <div className="absolute inset-y-0 right-0 mt-8 mr-1 flex items-center">
            {showNewPassword ? (
              <FaRegEyeSlash
                className="h-5 w-5 mr-14 cursor-pointer"
                onClick={toggleNewPasswordVisibility}
              />
            ) : (
              <FaRegEye
                className="h-5 w-5 mr-14 cursor-pointer"
                onClick={toggleNewPasswordVisibility}
              />
            )}
            <RiLockPasswordLine className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black text-white w-[50px] h-[42px] rounded-r-md" />
          </div>
        </div>
        {showStrengthHint && (
          <div className="mt-2 text-white text-sm">
            <p>
              Password must be at least 8 characters long and include uppercase
              letters, lowercase letters, numbers, and special characters.
            </p>
          </div>
        )}
        <div className="relative mb-6">
          <TextField
            label="Confirm Password:"
            name="confirm-password"
            type={showConfirmPassword ? "text" : "password"} // Toggle input type for Confirm Password
            placeholder="Enter confirm password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required={true}
            disabled={false}
            className=""
            icon={IoMdLock}
          />
          <div className="absolute inset-y-0 right-0 mt-8 flex mr-1 items-center">
            {showConfirmPassword ? (
              <FaRegEyeSlash
                className="h-5 w-5 mr-14 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              />
            ) : (
              <FaRegEye
                className="h-5 w-5 mr-14 cursor-pointer"
                onClick={toggleConfirmPasswordVisibility}
              />
            )}
            <RiLockPasswordLine className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-black text-white w-[50px] h-[42px] rounded-r-md" />
          </div>
        </div>
        <div className="flex space-x-10 justify-center">
          <button
            className="bg-white font-bold py-3 px-7 rounded focus:outline-none focus:shadow-outline"
            onClick={handleSubmit}
            disabled={!isButtonEnabled}
            id="submitbtn"
          >
            Submit
          </button>
          <button
            className="bg-white font-bold py-3 px-7 rounded focus:outline-none focus:shadow-outline"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
