import { ActionTypes } from "../constants/action-types";

const initialState = {
  states: [],
};

const DeactivateState = (state = initialState, { type, payload }) => {
  switch (type) {
    case ActionTypes.DEACTIVATE_STATE_SUCCESS:
      return {
        ...state,
        states: state.states.map((state) =>
          state.state_id === payload.stateId ? { ...state, status: payload.newStatus } : state
        ),
      };
    default:
      return state;
  }
};

export default DeactivateState;
